<template>
    <div class="page" :style="{ backgroundImage: 'url(./img/bg5.png)' }">
        <div class="flexCloumn" style="width:100%;height: 100%; overflow:hidden;">

            <div class="chartBox" style="width:100%;">
                <div class=" flexCenter" style="height: 60px; margin-top: 10px; display: flex; ">
                    <img src="/img/home_top_title.png" alt="" style="height:40px">
                    <!-- <img src="/img/home_top_1.png" alt="" style="height:40px">
            <div style="width: 400px; text-align: center;font-weight: bold;font-size: 20px;">
                南京市中等职业学校毕业生就业管理平台
            </div>
            <img src="/img/home_top_2.png" alt="" style="height:40px"> -->
                </div>
                <div class=" flexCenter" style="height: 20px; display: flex; ">
                    <div style="width: 400px; text-align: center;font-weight: bold;font-size: 20px;color:#5975FF;">
                        就业调查数据统计页
                    </div>
                </div>
                <div class=" flexBetween" style="height: 40px; margin-top: 0px; display: flex; padding: 0 27px;">
                    <div class="flexStart" style="height: 100%;">
                        <div class="flexCenter" @click="goPage"
                            style="font-size:18px; height: 100%;background-color:#5975FF;color:#fff;border-radius:5px;padding:0 32px;white-space:nowrap;">
                            <i class="el-icon-s-home mr10" style="font-size:20px;"></i>
                            返回
                        </div>
                        <div class="ml20" style="position: relative; height: 100%;">
                            <div class="flexCenter"
                                style="height: 100%;border-radius:5px;padding:0 32px;white-space:nowrap;border:1px solid #878787;">
                                {{ ckdYear }} 年度
                            </div>
                            <div style="position: absolute; height: 100%; top: 0;">
                                <el-select size="middle" style="opacity: 0; height: 100%;" v-model="ckdYear">
                                    <el-option :label="y" :value="y" v-for="(y, i) in years" :key="i"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="flexStart" style="height: 100%;">
                        <div>
                            毕业生总数：<span id="bycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                        </div>
                        <div style="margin-left:50px;">
                            样本总数：<span id="ybcount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                        </div>
                        <div style="margin-left:50px;">
                            样本就业人数：<span id="jycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                        </div>
                        <div style="margin-left:50px;">
                            样本待业人数：<span id="dycount" style="color:#6055BB;font-weight:700;font-size:20px;">***</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display:block;width:100%;height:1px;background:#fff;margin-top:20px;"></div>
            <div class="flexStretch" style="width:100%;height:calc(100% - 151px)">
                <div class="flexCloumnSingleCenter" style="width:86px;height:100%; justify-content:center;">
                    <div :class="(active == 1) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
                        @click="() => { active = 1; scrollToAnchor('anchor_school') }">
                        <img v-show="active != 1" src="/img/home_tab_1_0.png" alt="">
                        <img v-show="active == 1" src="/img/home_tab_1_1.png" alt="">
                        <div :class="(active == 1) ? 'activeColor' : 'normalColor'">学校</div>
                    </div>
                    <div style="width:1px;height:100px;border-right:1px dashed #5975FF;"></div>
                    <div :class="(active == 2) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
                        @click="() => { active = 2; scrollToAnchor('anchor_major') }">
                        <img v-show="active != 2" src="/img/home_tab_2_0.png" alt="">
                        <img v-show="active == 2" src="/img/home_tab_2_1.png" alt="">
                        <div :class="(active == 2) ? 'activeColor' : 'normalColor'">专业</div>
                    </div>
                    <div style="width:1px;height:100px;border-right:1px dashed #5975FF;"></div>
                    <div :class="(active == 3) ? 'flexCloumnSingleCenter tabItem activebg' : 'flexCloumnSingleCenter tabItem normalbg'"
                        @click="() => { active = 3; scrollToAnchor('anchor_city') }">
                        <img v-show="active != 3" src="/img/home_tab_3_0.png" alt="">
                        <img v-show="active == 3" src="/img/home_tab_3_1.png" alt="">
                        <div :class="(active == 3) ? 'activeColor' : 'normalColor'">全市</div>
                    </div>
                </div>
                <div style="width:1px;height:100%;background:#fff;"></div>
                <div class="" style="width:calc(100% - 87px); height:100%; overflow-y: auto;">
                    <concatChart :tPmid="0"></concatChart>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import concatChart from "./com/ivst_concat.vue"
export default {
    components: {
        concatChart
    },
    data() {
        return {
            active: 1,
            years: [],
            ckdYear: parseInt(sessionStorage.getItem("year") || new Date().getFullYear())
        }
    },
    mounted() {
        if (this.$store.state.pmid && this.$store.state.pmid != 1) {

            this.$router.push("/jiuyebzr")
        }

        let now = new Date().getFullYear();
        for (let i = now; i >= 2023; i--) {
            this.years.push(i);
        }
        if (!this.ckdYear) {
            this.ckdYear = now
        }

    },
    methods: {
        goPage() {

            this.$router.push("/jydcsjtj")
        },
        // 使用平滑滚动到达指定的锚点
        scrollToAnchor(anchorName) {
            // 获取带有该名称的元素
            var anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                // 使用平滑滚动到达该元素
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }


    }
}
</script>
<style scoped>
.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;

}

.tabItem {
    width: 60px;
    background-color: #5975FF;
    border-radius: 5px;
    padding: 20px 0;
    cursor: pointer;
    font-weight: 700;
}

.activebg {
    background-color: #5975FF;
}

.normalbg {
    background-color: #fff;
}

.tabItem .activeColor {
    color: #fff;
    margin-top: 10px;
}

.tabItem .normalColor {
    color: #6055BB;
    margin-top: 10px;
}
</style>
